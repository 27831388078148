.meat-holder {
  display: flex;
  align-items: center;
}

.textage {
  font-size: 18px;
  padding: 0px;
  text-align: center;
  height: 48px;
}

@media screen and (max-width: 600px) {
  .meat-holder {
    display: flex;
    flex-direction: column-reverse;
  }
}

.logout-button {
  font-size: 14px;
  cursor: pointer;
  padding: 5px;
}

.topnav {
  overflow: hidden;
  background-color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 0px;
  padding-right: 15px;
  height: 70px;
}

.navlogo-holder {
  border-radius: 5px;
  overflow: hidden;
  padding: 5px;
}

.navlogo {
  height: 60px;
}

.wrappem {
  display: flex;
  flex-wrap: wrap;
}

.big-holder {
  display: flex;
  margin: auto;
  width: 90%;
  height: 100vh;
}

.chatter {
  background: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  padding: 15px;
  max-width: 500px;
}

.overtext {
  font-variant-caps: all-small-caps;
  text-decoration: underline;
  font-size: 18px;
}

.lesson-pic {
  max-height: 100px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.lesson-contents {
  font-size: 14px;
}

.lesson-inner {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 300px;
}

.lesson-card {
  cursor: pointer;
  border: 1px solid lightgray;
  height: 300px;
  width: 300px;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin: 0em;
  margin-left: 0;
  margin-top: 0;
  flex-direction: column;
  justify-content: space-between;
}

.practice-card {
  cursor: pointer;
  border: 1px solid lightgray;
  width: 300px;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  margin: 1em;
  margin-left: 0;
  margin-top: 0;
  flex-direction: column;
  justify-content: space-between;
}

.lesson-card:hover {
  border: 1px solid black;
}

.practice-card:hover {
  border: 1px solid black;
}

.lesson-title {
  font-size: 28px;
  text-transform: uppercase;
}

.practice-title {
  font-size: 28px;
  text-transform: uppercase;
}

.launcher {
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
}

.staff-holder {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
}
  
.div1 { grid-area: 1 / 1 / 2 / 2; }
.div2 { grid-area: 1 / 2 / 2 / 3; }
.div3 { grid-area: 2 / 1 / 3 / 2; }
.div4 { grid-area: 2 / 2 / 3 / 3; }

@media screen and (max-width: 600px) {
  .div1 { grid-area: 1 / 1 / 2 / 2; max-width: 50vw;}
  .div2 { grid-area: 1 / 2 / 2 / 3; }
  .div3 { grid-area: 2 / 1 / 3 / 2; }
  .div4 { grid-area: 2 / 2 / 3 / 3; }
}

.plan {
  border-radius:10px;
  padding:10px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  height:150px;
  width:150px;
  border:2px dashed lightgray;
  cursor: pointer;
}

.active {
  border: 2px solid green;
}

.mc-staff {
  cursor: pointer;
  /*height: calc(0.5*(50vh - 35px));*/
  overflow: hidden;
}

/*.mc-staff:hover {
  transform: scale(1.01);
}*/

.scale-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  height: 100%;
}

body {
  font-family: myFirstFont;
  color: rgb(250,250,250);
}

@font-face {
  font-family: myFirstFont;
  src: url(WorkSans-Regular.ttf);
}

#pointer {
  cursor: pointer;
}

.right-side {
  display: flex;
  justify-content: center;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  position: relative;
  height: calc(100vh - 70px);
  width: 100%;
}

.booty-text {
  margin-bottom: 1em;
  font-family: "JetBrains Mono",monospace;
  font-size: 16px;
}

.left-side {
  background: black;
  /*border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;*/
  height: calc(100vh - 70px);
  min-width: 400px;
  max-width: 400px;
  /*min-width: 400px;*/
  /*padding: 15px;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .left-side {
    min-width: 100vw;
    width: 100vw;
    height: calc(50vh - 35px);
  }
  .right-side {
    width: 100vw;
    height: calc(50vh - 35px);
    padding: 0px;
  }
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --bot-size: 100px;
}

.botdiv-holder {
  position: relative;
  height: var(--bot-size);
  margin-bottom: 1em;
}

.bert {
  filter: blur(5px)
}

.botdiv {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: var(--bot-size);
  height: var(--bot-size);
  border-radius: var(--bot-size);
}

.botdiv2 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 110px;
  height: 110px;
  border-radius: var(--bot-size);
}

/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}

.initial {
  height: calc(100vh - 70px);
  background: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.atrest {
  animation: pulse 2s infinite !important;
}

@keyframes pulse {
	0% {
		transform: translateX(-50%) translateY(-50%) scale(1);
	}

	30% {
		transform: translateX(-50%) translateY(-50%) scale(1.1);
	}

	100% {
		transform: translateX(-50%) translateY(-50%) scale(1);

	}
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 60px;
  height: 48px;
  aspect-ratio: 2;
  --_g: no-repeat radial-gradient(circle closest-side,#FFF 90%,#FFFF);
  background: 
    var(--_g) 0%   50%,
    var(--_g) 50%  50%,
    var(--_g) 100% 50%;
  background-size: calc(100%/3) 50%;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
    20%{background-position:0%   0%, 50%  50%,100%  50%}
    40%{background-position:0% 100%, 50%   0%,100%  50%}
    60%{background-position:0%  50%, 50% 100%,100%   0%}
    80%{background-position:0%  50%, 50%  50%,100% 100%}
}
